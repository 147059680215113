'use client';

import { types } from './types';
import { types as loginTypes } from '@redux/auth/types';
import { types as orderTypes } from '../order/types';

import { DashboardInterface } from '@redux/interfaces';

export const INIT_STATE = {
  loading: false,
  error: false,
  currentTank: {
    id: 0,
    cesspool_name: '',
    estimated_sewage: null,
    last_estimation_update: null,
    ask_order: null,
    has_permission_to_find_company: false,
  },
  data: {
    email: '',
    first_name: '',
    client_properties: [],
    active_orders: [],
    invoice_unpaid: [],
    unread_notifications: 0,
    id: 0,
    order_to_accept: null,
  },
  fcmToken: '',
  notificationsInfo: {
    count: 0,
    next: null,
    previous: null,
  },
  notifications: [],
};

export default function reducer(
  state: DashboardInterface = INIT_STATE,
  action: any
): DashboardInterface {
  switch (action.type) {
    case loginTypes.LOGIN_REQUEST:
      return {
        ...INIT_STATE,
      };
    case types.SET_FIREBASE_TOKEN_REQUEST:
    case types.DELETE_FIREBASE_TOKEN_REQUEST:
    case types.GET_UNREAD_NOTIFICATIONS_REQUEST:
    case types.GET_DASHBOARD_INFO_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case types.GET_DASHBOARD_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload,
      };

    case types.SET_FIREBASE_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        fcmToken: action.payload.registration_token,
      };
    case types.DELETE_FIREBASE_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case types.GET_UNREAD_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        notificationsInfo: {
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous,
        },
        notifications: [...state.notifications, ...action.payload.results],
      };
    case types.CLEAR_READ_NOTIFICATIONS:
      return {
        ...state,
        notifications: [],
      };
    case types.SET_CURRENT_TANK:
      return {
        ...state,
        currentTank: action.data,
      };
    case orderTypes.CANCEL_ORDER_PROPOSITION_SUCCESS:
      return {
        ...state,
        currentTank: INIT_STATE.currentTank,
      };
    default:
      return state;
  }
}
