'use client';

import { put, takeLatest } from 'redux-saga/effects';
import { types } from './types';
import { types as dashboardTypes } from '../dashboard/types';
import { types as userCesspoolTypes } from '../userCesspools/types';

import { createSaga } from '@redux/sagas';
import { DefaultApiAction } from '@redux/interfaces';
import { toast } from 'react-toastify';
import downloadPdf from '@utils/DownloadPdf';

export function* getClientCompaniesSaga() {
  yield takeLatest(
    types.GET_CLIENT_COMPANIES_REQUEST,
    createSaga('GET_CLIENT_COMPANIES', '/client/companies', 'GET')
  );
}

export function* getClientCompaniesFailureSaga() {
  yield takeLatest(
    types.GET_CLIENT_COMPANIES_FAILURE,
    function* (action: DefaultApiAction) {
      if (action.payload.code === 400) {
        const { body } = action.payload;

        switch (body.exception_name) {
          case 'ClientPropertyGeopositionMissingException':
            toast.error('Zlokalizuj swój adres i spróbuj ponownie.');
            break;
          case 'ClientPropertyCesspoolSizeMissingException':
            toast.error(
              'Upenij się, że rodzaj zbiornika został poprawie wprowadzony'
            );
            break;
        }
      }
    }
  );
}

export function* getCompanyDatesSaga() {
  yield takeLatest(
    types.GET_COMPANY_DATES_REQUEST,
    createSaga('GET_COMPANY_DATES', '/client/order/dates', 'GET')
  );
}

export function* addNewOrderSaga() {
  yield takeLatest(
    types.ADD_NEW_APP_ORDER_REQUEST,
    createSaga('ADD_NEW_APP_ORDER', '/client/order', 'POST')
  );
}

export function* addNewOrderSuccessSaga() {
  yield takeLatest(
    types.ADD_NEW_APP_ORDER_SUCCESS,
    function* (action: DefaultApiAction) {
      toast.success('Udało się złożyć zamówienie');
      yield put({ type: userCesspoolTypes.GET_CLIENT_PROPERTY_REQUEST });
    }
  );
}

export function* addNewOrderFailureSaga() {
  yield takeLatest(
    types.ADD_NEW_APP_ORDER_FAILURE,
    function* (action: DefaultApiAction) {
      if (action.payload.code === 400) {
        const { body } = action.payload;

        switch (body.exception_name) {
          case 'ClientPropertyHasUnpaidInvoice':
            toast.error(
              'Nie możesz zamawiać, aż nie opłacisz zaległych faktur dla tego zbiornika'
            );
            break;
          case 'ClientMissingPersonalInformationException':
            toast.error(
              'Uzupełnij swoje dane, bez tego nie możesz złożyć zamówienia'
            );
            break;
          case 'ClientPropertyDataMissingException':
            toast.error(
              'Uzupełnij dane zbiornika, bez tego nie możesz złożyć zamówienia.'
            );
            break;

          case 'ClientPropertyHasPendingOrderException':
            toast.error('Masz już zamówienie na tym zbiorniku.');
            break;
          case 'ClientHasUnpaidInvoiceAfterDeadline':
            toast.error('Pilnie opłać zaległą fakturę');
            break;
        }
      }
    }
  );
}

export function* deleteOrderSaga() {
  yield takeLatest(
    types.DELETE_ORDER_REQUEST,
    createSaga('DELETE_ORDER', '/client/order', 'DELETE')
  );
}

export function* deleteOrderSuccessSaga() {
  yield takeLatest(
    types.DELETE_ORDER_SUCCESS,
    function* (action: DefaultApiAction) {
      toast.success('Udało się anulować zamówienie');
      yield put({ type: userCesspoolTypes.GET_CLIENT_PROPERTY_REQUEST });
    }
  );
}

export function* deleteOrderFailureSaga() {
  yield takeLatest(
    types.DELETE_ORDER_FAILURE,
    function* (action: DefaultApiAction) {
      if (action.payload.code === 400) {
        const { body } = action.payload;

        switch (body.exception_name) {
          case 'ClientCanNotCancelOrderException':
            toast.error('Nie można anulować tego zamówienia');
            break;
        }
      }
    }
  );
}

export function* getContractSaga() {
  yield takeLatest(
    types.GET_DRIVER_CONTRACT_REQUEST,
    createSaga('GET_DRIVER_CONTRACT', '/client/draft-agreement', 'GET')
  );
}

export function* getContractSuccessSaga() {
  yield takeLatest(
    types.GET_DRIVER_CONTRACT_SUCCESS,
    function* (action: DefaultApiAction) {
      const { file, name } = action.payload;
      downloadPdf(name, file);
    }
  );
}

export function* getContractFailureSaga() {
  yield takeLatest(
    types.GET_DRIVER_CONTRACT_FAILURE,
    function* (action: DefaultApiAction) {
      toast.error('Nie udało się pobrać umowy');
    }
  );
}

export function* askForDriverInCommuneSaga() {
  yield takeLatest(
    types.ASK_FOR_DRIVER_IN_COMMUNE_REQUEST,
    createSaga('ASK_FOR_DRIVER_IN_COMMUNE', '/client/ask-for-driver', 'POST')
  );
}

export function* askForDriverInCommuneFailureSaga() {
  yield takeLatest(
    types.ASK_FOR_DRIVER_IN_COMMUNE_FAILURE,
    function* (action: DefaultApiAction) {
      if (action.payload.code === 400) {
        const { body } = action.payload;

        switch (body.exception_name) {
          case 'ClientPropertyDataMissingException':
            toast.error(
              'Uzupełnij dane zbiornika, bez tego nie możesz wysłać zapytania o znalezienie firmy.'
            );
            break;
          default:
            toast.error(
              'Nie udało się wysłać zapytania o znalezienie firmy. Spróbuj ponownie później.'
            );
            break;
        }
      }
    }
  );
}

export function* askForDriverInCommuneSuccessSaga() {
  yield takeLatest(
    types.ASK_FOR_DRIVER_IN_COMMUNE_SUCCESS,
    function* (action: DefaultApiAction) {
      yield put({ type: dashboardTypes.GET_DASHBOARD_INFO_REQUEST });
      toast.success('Udało się wysłać zapytanie o znalezienie firmy.');
    }
  );
}

export function* acceptOrderPropositionSaga() {
  yield takeLatest(
    types.ACCEPT_ORDER_PROPOSITION_REQUEST,
    createSaga(
      'ACCEPT_ORDER_PROPOSITION',
      '/client/ask-for-driver/confirm',
      'POST'
    )
  );
}

export function* acceptOrderPropositionFailureSaga() {
  yield takeLatest(
    types.ACCEPT_ORDER_PROPOSITION_FAILURE,
    function* (action: DefaultApiAction) {
      toast.error(
        'Nie udało się zaakceptować wywozu. Spróbuj ponownie później.'
      );
    }
  );
}

export function* acceptOrderPropositionSuccessSaga() {
  yield takeLatest(
    types.ACCEPT_ORDER_PROPOSITION_SUCCESS,
    function* (action: DefaultApiAction) {
      toast.success('Zaakceptowano propozycję odbioru ścieków.');
      yield put({ type: dashboardTypes.GET_DASHBOARD_INFO_REQUEST });
    }
  );
}

export function* cancelOrderPropositionSaga() {
  yield takeLatest(
    types.CANCEL_ORDER_PROPOSITION_REQUEST,
    createSaga(
      'CANCEL_ORDER_PROPOSITION',
      '/client/ask-for-driver/cancel',
      'POST'
    )
  );
}

export function* cancelOrderPropositionFailureSaga() {
  yield takeLatest(
    types.CANCEL_ORDER_PROPOSITION_FAILURE,
    function* (action: DefaultApiAction) {
      toast.error(
        'Nie udało się anulować zgłoszonego zamówienia. Spróbuj ponownie później.'
      );
    }
  );
}

export function* cancelOrderPropositionSuccessSaga() {
  yield takeLatest(
    types.CANCEL_ORDER_PROPOSITION_SUCCESS,
    function* (action: DefaultApiAction) {
      toast.success('Anulowano propozycję odbioru ścieków.');
      yield put({ type: dashboardTypes.GET_DASHBOARD_INFO_REQUEST });
    }
  );
}

export function* getOrderPropositionDetailsSaga() {
  yield takeLatest(
    types.GET_ORDER_PROPOSITION_DETAILS_REQUEST,
    createSaga(
      'GET_ORDER_PROPOSITION_DETAILS',
      '/client/ask-for-driver/details',
      'GET'
    )
  );
}

export function* getOrderPropositionDetailsFailureSaga() {
  yield takeLatest(
    types.GET_ORDER_PROPOSITION_DETAILS_FAILURE,
    function* (action: DefaultApiAction) {
      toast.error('Nie udało się pobrać szczgółów. Spróbuj ponownie później.');
    }
  );
}
