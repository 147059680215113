'use client';

import { put, takeLatest } from 'redux-saga/effects';
import { types } from './types';
import { createSaga } from '@redux/sagas';
import { DefaultApiAction } from '@redux/interfaces';
import { toast } from 'react-toastify';
import downloadPdf from '@utils/DownloadPdf';

export function* addNewExportSaga() {
  yield takeLatest(
    types.ADD_NEW_EXPORT_REQUEST,
    createSaga('ADD_NEW_EXPORT', '/client/removed-amount/', 'POST')
  );
}

export function* addNewExportSuccessSaga() {
  yield takeLatest(
    types.ADD_NEW_EXPORT_SUCCESS,
    function* (action: DefaultApiAction) {
      toast.success('Pomyślnie dodano wywóz');
    }
  );
}

export function* addNewExportFailureSaga() {
  yield takeLatest(
    types.ADD_NEW_EXPORT_FAILURE,
    function* (action: DefaultApiAction) {
      if (action.payload.code === 400) {
        toast.error('Sprawdź czy wywóz nie został dodany wcześniej');
      } else {
        toast.error('Nie udało się dodać wywozu');
      }
    }
  );
}

export function* findCompaniesSaga() {
  yield takeLatest(
    types.FIND_COMPANY_REQUEST,
    createSaga('FIND_COMPANY', '/client/find-company', 'GET')
  );
}

export function* findCompaniesSuccessSaga() {
  yield takeLatest(
    types.FIND_COMPANY_SUCCESS,
    function* (action: DefaultApiAction) {
      if (action.payload.length === 0)
        yield put({ type: types.SHOW_NO_COMPANY_INFO });
    }
  );
}

export function* findCompanyFailureSaga() {
  yield takeLatest(
    types.FIND_COMPANY_FAILURE,
    function* (action: DefaultApiAction) {
      if (action.payload.code === 400) {
        const { body } = action.payload;

        switch (body.exception_name) {
          case 'ClientMissingPersonalInformationException':
            toast.error('Wystąpił błąd. Uzupełnij swoje dane');
            break;
        }
      }
    }
  );
}

export function* getHistoryDropSaga() {
  yield takeLatest(
    types.GET_HISTORY_DROP_REQUEST,
    createSaga('GET_HISTORY_DROP', '/client/order/history', 'GET')
  );
}

export function* deleteExportSaga() {
  yield takeLatest(
    types.DELETE_EXPORT_REQUEST,
    createSaga('DELETE_EXPORT', '/client/removed-amount', 'DELETE')
  );
}

export function* deleteExportSuccessSaga() {
  yield takeLatest(
    types.DELETE_EXPORT_SUCCESS,
    function* (action: DefaultApiAction) {
      toast.success('Pomyślnie usunięto wywóz');
    }
  );
}

export function* deleteExportFailureSaga() {
  yield takeLatest(
    types.DELETE_EXPORT_FAILURE,
    function* (action: DefaultApiAction) {
      toast.error('Nie udało się usunąć wywozu');
    }
  );
}

export function* updateExportSaga() {
  yield takeLatest(
    types.UPDATE_EXPORT_REQUEST,
    createSaga('UPDATE_EXPORT', '/client/removed-amount', 'PUT')
  );
}

export function* updateExportSuccessSaga() {
  yield takeLatest(
    types.UPDATE_EXPORT_SUCCESS,
    function* (action: DefaultApiAction) {
      toast.success('Pomyślnie edytowano wywóz');
    }
  );
}

export function* updateExportFailureSaga() {
  yield takeLatest(
    types.UPDATE_EXPORT_FAILURE,
    function* (action: DefaultApiAction) {
      toast.error('Nie udało się edytować wywozu');
    }
  );
}

export function* getInvoiceSaga() {
  yield takeLatest(
    types.GET_INVOICE_REQUEST,
    createSaga('GET_INVOICE', '/client/invoice', 'GET')
  );
}

export function* getInvoiceSuccessSaga() {
  yield takeLatest(
    types.GET_INVOICE_SUCCESS,
    function* (action: DefaultApiAction) {
      const { file, name } = action.payload;
      downloadPdf(name, file);
    }
  );
}

export function* getInvoiceFailureSaga() {
  yield takeLatest(
    types.GET_INVOICE_FAILURE,
    function* (action: DefaultApiAction) {
      toast.error('Nie udało się pobrać faktury.');
    }
  );
}

export function* getComplaintSaga() {
  yield takeLatest(
    types.GET_COMPLAINT_REQUEST,
    createSaga('GET_COMPLAINT', '/client/complaints', 'GET')
  );
}

export function* getComplaintFailureSaga() {
  yield takeLatest(
    types.GET_COMPLAINT_FAILURE,
    function* (action: DefaultApiAction) {
      toast.error('Nie udało się pobrać szczegółów reklamacji.');
    }
  );
}

export function* addComplaintSaga() {
  yield takeLatest(
    types.ADD_COMPLAINT_REQUEST,
    createSaga('ADD_COMPLAINT', '/client/complaints', 'POST')
  );
}

export function* addComplaintSuccessSaga() {
  yield takeLatest(
    types.ADD_COMPLAINT_SUCCESS,
    function* (action: DefaultApiAction) {
      toast.success('Pomyślnie zgłoszono reklamację');
    }
  );
}

export function* addComplaintFailureSaga() {
  yield takeLatest(
    types.ADD_COMPLAINT_FAILURE,
    function* (action: DefaultApiAction) {
      toast.error('Nie udało się Zgłosić reklamacji.');
    }
  );
}
