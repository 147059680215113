'use client';

import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { createReduxEnhancer } from '@sentry/react';

import rootSaga from '@redux/sagas';
import rootReducer from '@redux/reducers';
import { RootState } from '@redux/interfaces';

export const makeStore = () => {
  const sentryReduxEnhancer = createReduxEnhancer({
    configureScopeWithState: (scope: any, state: RootState) => {
      scope.setTag('email', state.dashboard.data.email);
      scope.setTag('selectedTankId', state.userCesspools.selectedTankId);
    },
  });

  const sagaMiddleware = createSagaMiddleware();
  const rootReducers = combineReducers(rootReducer);

  const devModeEnable = process.env.NODE_ENV !== 'production';

  const store = configureStore({
    reducer: rootReducers,
    devTools: devModeEnable,
    enhancers: [sentryReduxEnhancer],
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: false,
        immutableCheck: false,
        serializableCheck: false,
      }).concat(sagaMiddleware),
  });
  sagaMiddleware.run(rootSaga, null);
  return store;
};

export const store = makeStore();
