'use client';

import { put, takeLatest } from 'redux-saga/effects';
import { types } from './types';
import { createSaga } from '@redux/sagas';
import { DefaultApiAction } from '@redux/interfaces';
import downloadPdf from '@utils/DownloadPdf';
import { toast } from 'react-toastify';

// Downloading agreement with id
export function* downloadAgreementSaga() {
  yield takeLatest(
    types.DOWNLOAD_AGREEMENT_REQUEST,
    createSaga('DOWNLOAD_AGREEMENT', '/client/agreement', 'GET')
  );
}

export function* downloadAgreementSuccessSaga() {
  yield takeLatest(
    types.DOWNLOAD_AGREEMENT_SUCCESS,
    function* (action: DefaultApiAction) {
      const { file, name } = action.payload;
      downloadPdf(name, file);
    }
  );
}

export function* downloadAgreementFailureSaga() {
  yield takeLatest(
    types.DOWNLOAD_AGREEMENT_FAILURE,
    function* (action: DefaultApiAction) {
      toast.error('Wystąpił problem z pobieraniem umowy');
    }
  );
}

// Get agreement with id

export function* getSingleAgreementSaga() {
  yield takeLatest(
    types.GET_SINGLE_AGREEMENT_REQUEST,
    createSaga('GET_SINGLE_AGREEMENT', '/client/agreement', 'GET')
  );
}

// Get agreement list

export function* getAgreementListSaga() {
  yield takeLatest(
    types.GET_AGREEMENT_LIST_REQUEST,
    createSaga('GET_AGREEMENT_LIST', '/client/agreement/', 'GET')
  );
}

export function* getAgreementListSuccessSaga() {
  yield takeLatest(
    types.GET_AGREEMENT_LIST_SUCCESS,
    function* (action: DefaultApiAction) {
      // console.log('action.payload', action.payload);
    }
  );
}

//  Add new agreement
export function* addNewAgreementSaga() {
  yield takeLatest(
    types.ADD_NEW_AGREEMENT_REQUEST,
    createSaga('ADD_NEW_AGREEMENT', '/client/agreement/', 'POST')
  );
}

export function* addNewAgreementSuccessSaga() {
  yield takeLatest(types.ADD_NEW_AGREEMENT_SUCCESS, function* () {
    toast.success('Pomyślnie dodano nową umowę do systemu');
    yield put({ type: types.GET_AGREEMENT_LIST_REQUEST });
  });
}

export function* addNewAgreementFailureSaga() {
  yield takeLatest(types.ADD_NEW_AGREEMENT_FAILURE, function* () {
    toast.error('Wystąpił błąd podczas dodawania umowy');
  });
}

// Edit existing agreement

export function* editAgreementSaga() {
  yield takeLatest(
    types.EDIT_AGREEMENT_REQUEST,
    createSaga('EDIT_AGREEMENT', '/client/agreement', 'PATCH')
  );
}

export function* editAgreementSuccessSaga() {
  yield takeLatest(types.EDIT_AGREEMENT_SUCCESS, function* () {
    toast.success('Pomyślnie edytowano umowę w systemie');
    yield put({ type: types.GET_AGREEMENT_LIST_REQUEST });
  });
}

export function* editAgreementFailureSaga() {
  yield takeLatest(types.EDIT_AGREEMENT_FAILURE, function* () {
    toast.error('Wystąpił błąd podczas edycji umowy');
  });
}

// Delete agreement

export function* deleteAgreementSaga() {
  yield takeLatest(
    types.DELETE_AGREEMENT_REQUEST,
    createSaga('DELETE_AGREEMENT', '/client/agreement', 'DELETE')
  );
}

export function* deleteAgreementSuccessSaga() {
  yield takeLatest(types.DELETE_AGREEMENT_SUCCESS, function* () {
    yield put({ type: types.GET_AGREEMENT_LIST_REQUEST });
  });
}

export function* deleteAgreementFailureSaga() {
  yield takeLatest(types.DELETE_AGREEMENT_FAILURE, function* () {
    toast.error('Nie udało się usunąć umowy');
  });
}
