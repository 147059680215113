'use client';

import {
  useNavigate,
  NavigateFunction,
  Location,
  useLocation,
} from 'react-router-dom';

export let globalNavigate: NavigateFunction;
export let globalLocation: Location;

export const GlobalHistory = ({ children }: { children: any }) => {
  globalNavigate = useNavigate();
  globalLocation = useLocation();

  return <>{children}</>;
};
