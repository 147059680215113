'use client';

import { ClientData } from '@redux/interfaces';
import { types } from '@redux/userSettings/myAccount/types';
import { types as loginTypes } from '@redux/auth/types';

export const INIT_STATE = {
  loading: false,
  error: false,
  client: {
    first_name: '',
    last_name: '',
    email: '',
    promotions: false,
    phone_nb: '',
    client_company_name: null,
  },
  billingData: {
    nip: '',
    billing_type: '',
    full_name: '',
    street: '',
    house_nb: '',
    flat_nb: '',
    city: '',
    postal_code: '',
    street_sym: 0,
    locality_sym: '',
    province: { name: '', code: '' },
  },
  clientLoaded: false,
};

export default function reducer(
  state: ClientData = INIT_STATE,
  action: any
): ClientData {
  switch (action.type) {
    case loginTypes.LOGIN_REQUEST:
      return {
        ...INIT_STATE,
      };
    case types.UPDATE_CONTACT_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case types.UPDATE_CONTACT_DATA_SUCCESS:
      return {
        ...state,
        client: action.payload,
        loading: false,
        error: false,
      };
    case types.UPDATE_CONTACT_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case types.GET_CONTACT_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case types.GET_CONTACT_DATA_SUCCESS:
      return {
        ...state,
        client: action.payload,
        loading: false,
        error: false,
        clientLoaded: true,
      };
    case types.GET_CONTACT_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case types.GET_BILLING_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case types.GET_BILLING_DATA_SUCCESS:
      return {
        ...state,
        billingData: action.payload,
        loading: false,
        error: false,
      };
    default:
      return state;
  }
}
