'use client';

export const types = {
  SET_INVOICE_TO_PAY: 'SET_INVOICE_TO_PAY',
  RESET_INVOICE_TO_PAY: 'RESET_INVOICE_TO_PAY',

  GET_CLIENT_COMPANIES_REQUEST: 'GET_CLIENT_COMPANIES_REQUEST',
  GET_CLIENT_COMPANIES_SUCCESS: 'GET_CLIENT_COMPANIES_SUCCESS',
  GET_CLIENT_COMPANIES_FAILURE: 'GET_CLIENT_COMPANIES_FAILURE',

  ADD_MANUAL_PICKUP_REQUEST: 'ADD_MANUAL_PICKUP_REQUEST',
  ADD_MANUAL_PICKUP_SUCCESS: 'ADD_MANUAL_PICKUP_SUCCESS',

  GET_COMPANY_DATES_REQUEST: 'GET_COMPANY_DATES_REQUEST',
  GET_COMPANY_DATES_SUCCESS: 'GET_COMPANY_DATES_SUCCESS',
  GET_COMPANY_DATES_FAILURE: 'GET_COMPANY_DATES_FAILURE',
  GET_COMPANY_DATES_RESET: 'GET_COMPANY_DATES_RESET',

  ADD_NEW_APP_ORDER_REQUEST: 'ADD_NEW_APP_ORDER_REQUEST',
  ADD_NEW_APP_ORDER_SUCCESS: 'ADD_NEW_APP_ORDER_SUCCESS',
  ADD_NEW_APP_ORDER_FAILURE: 'ADD_NEW_APP_ORDER_FAILURE',
  RESET_ORDER_ADDED: 'RESET_ORDER_ADDED',

  SET_ORDER_ID_TO_DELETE: 'SET_ORDER_ID_TO_DELETE',
  RESET_ORDER_ID_TO_DELETE: 'RESET_ORDER_ID_TO_DELETE',

  DELETE_ORDER_REQUEST: 'DELETE_ORDER_REQUEST',
  DELETE_ORDER_SUCCESS: 'DELETE_ORDER_SUCCESS',
  DELETE_ORDER_FAILURE: 'DELETE_ORDER_FAILURE',
  RESET_ORDER_DELETE: 'RESET_ORDER_DELETE',

  GET_DRIVER_CONTRACT_REQUEST: 'GET_DRIVER_CONTRACT_REQUEST',
  GET_DRIVER_CONTRACT_SUCCESS: 'GET_DRIVER_CONTRACT_SUCCESS',
  GET_DRIVER_CONTRACT_FAILURE: 'GET_DRIVER_CONTRACT_FAILURE',

  ASK_FOR_DRIVER_IN_COMMUNE_REQUEST: 'ASK_FOR_DRIVER_IN_COMMUNE_REQUEST',
  ASK_FOR_DRIVER_IN_COMMUNE_SUCCESS: 'ASK_FOR_DRIVER_IN_COMMUNE_SUCCESS',
  ASK_FOR_DRIVER_IN_COMMUNE_FAILURE: 'ASK_FOR_DRIVER_IN_COMMUNE_FAILURE',

  GET_ORDER_PROPOSITION_DETAILS_REQUEST:
    'GET_ORDER_PROPOSITION_DETAILS_REQUEST',
  GET_ORDER_PROPOSITION_DETAILS_SUCCESS:
    'GET_ORDER_PROPOSITION_DETAILS_SUCCESS',
  GET_ORDER_PROPOSITION_DETAILS_FAILURE:
    'GET_ORDER_PROPOSITION_DETAILS_FAILURE',

  ACCEPT_ORDER_PROPOSITION_REQUEST: 'ACCEPT_ORDER_PROPOSITION_REQUEST',
  ACCEPT_ORDER_PROPOSITION_SUCCESS: 'ACCEPT_ORDER_PROPOSITION_SUCCESS',
  ACCEPT_ORDER_PROPOSITION_FAILURE: 'ACCEPT_ORDER_PROPOSITION_FAILURE',

  CANCEL_ORDER_PROPOSITION_REQUEST: 'CANCEL_ORDER_PROPOSITION_REQUEST',
  CANCEL_ORDER_PROPOSITION_SUCCESS: 'CANCEL_ORDER_PROPOSITION_SUCCESS',
  CANCEL_ORDER_PROPOSITION_FAILURE: 'CANCEL_ORDER_PROPOSITION_FAILURE',
};
