import React from 'react';
import { Container } from '@mui/material';
import { styled } from '@mui/material/styles';

type Props = {
  children: React.ReactNode;
  additionalStyle?: object | null;
};

const CustomContainer = styled(Container)`
  align-items: center;
`;

const BackgroundImageLayout = ({ children, additionalStyle }: Props) => {
  return (
    <CustomContainer
      sx={[
        {
          minHeight: '100vh',
          pb: 2,
          display: 'flex',
          justifyContent: 'center',
          pt: 2,
          backgroundImage: 'url("/images/Background.webp")',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        },
        additionalStyle !== undefined && { ...additionalStyle },
      ]}
      maxWidth={false}
    >
      {children}
    </CustomContainer>
  );
};

export default BackgroundImageLayout;
