import { globalNavigate } from '../../navigation/globalHistory';

interface IAuthService {
  setToken: (token: string) => void;
  logoutUser: () => void;
  isUserLogged: () => boolean;
}

const authService: IAuthService = {
  setToken(token) {
    if (window && window.sessionStorage) {
      sessionStorage.setItem('TOKEN', token);
    }
  },
  logoutUser() {
    if (window && window.sessionStorage) {
      sessionStorage.removeItem('TOKEN');
    }
    globalNavigate('/login');
  },
  isUserLogged() {
    if (window && window.sessionStorage) {
      const token = sessionStorage.getItem('TOKEN');
      return Boolean(token);
    }
    return false;
  },
};

export default authService;
