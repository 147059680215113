'use client';

export const types = {
  ADD_NEW_EXPORT_REQUEST: 'ADD_NEW_EXPORT_REQUEST',
  ADD_NEW_EXPORT_SUCCESS: 'ADD_NEW_EXPORT_SUCCESS',
  ADD_NEW_EXPORT_FAILURE: 'ADD_NEW_EXPORT_FAILURE',

  DELETE_EXPORT_REQUEST: 'DELETE_EXPORT_REQUEST',
  DELETE_EXPORT_SUCCESS: 'DELETE_EXPORT_SUCCESS',
  DELETE_EXPORT_FAILURE: 'DELETE_EXPORT_FAILURE',

  UPDATE_EXPORT_REQUEST: 'UPDATE_EXPORT_REQUEST',
  UPDATE_EXPORT_SUCCESS: 'UPDATE_EXPORT_SUCCESS',
  UPDATE_EXPORT_FAILURE: 'UPDATE_EXPORT_FAILURE',

  FIND_COMPANY_REQUEST: 'FIND_COMPANY_REQUEST',
  FIND_COMPANY_SUCCESS: 'FIND_COMPANY_SUCCESS',
  FIND_COMPANY_FAILURE: 'FIND_COMPANY_FAILURE',
  FIND_COMPANY_RESET: 'FIND_COMPANY_RESET',
  SHOW_NO_COMPANY_INFO: 'SHOW_NO_COMPANY_INFO',

  CONFIRM_IS_ADDED: 'CONFIRM_IS_ADDED',

  GET_HISTORY_DROP_REQUEST: 'GET_HISTORY_DROP_REQUEST',
  GET_HISTORY_DROP_SUCCESS: 'GET_HISTORY_DROP_SUCCESS',
  GET_HISTORY_DROP_FAILURE: 'GET_HISTORY_DROP_FAILURE',

  GET_INVOICE_REQUEST: 'GET_INVOICE_REQUEST',
  GET_INVOICE_SUCCESS: 'GET_INVOICE_SUCCESS',
  GET_INVOICE_FAILURE: 'GET_INVOICE_FAILURE',

  GET_COMPLAINT_REQUEST: 'GET_COMPLAINT_REQUEST',
  GET_COMPLAINT_SUCCESS: 'GET_COMPLAINT_SUCCESS',
  GET_COMPLAINT_FAILURE: 'GET_COMPLAINT_FAILURE',

  RESET_COMPLAINT: 'RESET_COMPLAINT',

  ADD_COMPLAINT_REQUEST: 'ADD_COMPLAINT_REQUEST',
  ADD_COMPLAINT_SUCCESS: 'ADD_COMPLAINT_SUCCESS',
  ADD_COMPLAINT_FAILURE: 'ADD_COMPLAINT_FAILURE',
};
