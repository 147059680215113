'use client';

import { PaymentInterface } from '@redux/interfaces';
import { types } from './types';
import { types as loginTypes } from '@redux/auth/types';

export const INIT_STATE = {
  loading: false,
  error: false,
  creditCard: [],
  redirectedFromDashboard: false,
  posId: null,
  secureBody: null,
  addedCard: false,
  deleteCard: false,
  actionNeeded: false,
  isPaid: false,
  cvvRedirectUri: '',
  payuStatus: {
    message: '',
    payu_status: '',
    redirect_uri: '',
    status: '',
  },
};

export default function reducer(
  state: PaymentInterface = INIT_STATE,
  action: any
): PaymentInterface {
  switch (action.type) {
    case loginTypes.LOGIN_REQUEST:
      return {
        ...INIT_STATE,
      };
    case types.ADD_CREDIT_CARD_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        addedCard: false,
      };
    case types.ADD_CREDIT_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        posId: null,
        payuStatus: action.payload,
      };
    case types.CARD_ADDED_SUCCESSFULLY:
      return {
        ...state,
        addedCard: true,
      };
    case types.ACTION_NEEDED_TO_ADD:
      return {
        ...state,
        actionNeeded: true,
      };
    case types.ADD_CREDIT_CARD_FAILURE:
      return {
        ...state,
        loading: false,
        secureBody: action.payload,
      };

    case types.GET_POS_ID_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case types.GET_POS_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        posId: action.payload?.pos_id,
      };
    case types.GET_POS_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case types.GET_CREDIT_CARD_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case types.GET_CREDIT_CARD_SUCCESS:
      return {
        ...state,
        creditCard: action.payload,
        loading: false,
        error: false,
        addedCard: false,
      };
    case types.ACTION_DONE:
      return {
        ...state,
        actionNeeded: false,
      };
    case types.GET_CREDIT_CARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case types.DELETE_CREDIT_CARD_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case types.DELETE_CREDIT_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        addedCard: false,
        deleteCard: true,
      };

    case types.DELETE_CARD_DONE:
      return {
        ...state,
        deleteCard: false,
      };
    case types.DELETE_CREDIT_CARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case types.ADD_CARD_REDIRECTED_ON:
      return {
        ...state,
        redirectedFromDashboard: true,
      };
    case types.ADD_CARD_REDIRECTED_OFF:
      return {
        ...state,
        redirectedFromDashboard: false,
      };
    case types.PAY_FOR_INVOICE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.PAY_FOR_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        cvvRedirectUri: action.payload?.redirect_uri,
      };
    case types.PAY_FOR_INVOICE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case types.INVOICE_PAID_SUCCESSFULLY:
      return {
        ...state,
        isPaid: true,
      };
    case types.INVOICE_PAID_RESET:
      return {
        ...state,
        isPaid: false,
        cvvRedirectUri: '',
      };
    default:
      return state;
  }
}
