'use client';

import { AuthApiResult } from '@redux/interfaces';
import { types } from '@redux/auth/types';

import authService from '@services/authService';

export const INIT_STATE = {
  loading: false,
  logged: false,
  error: false,
  pin: null,
  userId: 0,
  isOffline: false,
  documents: [],
  userEmail: '',
  userRegistered: false,
};

export default function reducer(
  state: AuthApiResult = INIT_STATE,
  action: any
): AuthApiResult {
  switch (action.type) {
    case types.GET_CAPTCHA_REQUEST:
    case types.GET_USER_EMAIL_REQUEST:
    case types.CONFIRM_EMAIL_REQUEST:
    case types.CREATE_ACCOUNT_REQUEST:
    case types.RESET_PASSWORD_REQUEST:
    case types.RESET_PASSWORD_CONFIRM_REQUEST:
    case types.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        logged: false,
        userEmail: '',
        userRegistered: false,
      };
    case types.SAVE_PIN:
      return {
        ...state,
        pin: action.data,
      };
    case types.DELETE_PIN:
      return {
        ...state,
        pin: null,
      };
    case types.LOGIN_SUCCESS:
      authService.setToken(action.payload.key);
      return {
        ...state,
        loading: false,
        error: false,
        logged: true,
      };
    case types.CONFIRM_EMAIL_SUCCESS:
    case types.RESET_PASSWORD_SUCCESS:
    case types.RESET_PASSWORD_CONFIRM_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case types.CREATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        userRegistered: true,
      };
    case types.GET_USER_EMAIL_FAILURE:
    case types.CREATE_ACCOUNT_FAILURE:
    case types.GET_CAPTCHA_FAILURE:
    case types.RESET_PASSWORD_FAILURE:
    case types.RESET_PASSWORD_CONFIRM_FAILURE:
    case types.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        logged: false,
        error: true,
        userEmail: '',
      };
    case types.LOGOUT_USER:
      return {
        ...state,
        logged: false,
      };
    case types.GET_USER_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_USER_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        userId: action.payload?.pk,
      };
    case types.SET_BACK_ONLINE:
      return {
        ...state,
        isOffline: false,
      };
    case types.SET_BACK_OFFLINE:
      return {
        ...state,
        isOffline: true,
      };
    case types.GET_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documents: action.payload.documents,
      };
    case types.GET_USER_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        userEmail: action.payload.email,
      };
    case types.LOGIN_FROM_BOSS:
      return {
        ...state,
        logged: true,
      };
    default:
      return state;
  }
}
