import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { Box, BoxProps, Typography, Paper } from '@mui/material';
import theme from '@styles/theme';

const { primary } = theme.palette;

export const GradientButton = styled(LoadingButton)`
  margin-top: ${({ orange }: { orange?: boolean }) => (orange ? '1em' : '3em')};
  margin-bottom: 3em;
  padding: 10px 30px 10px 30px;
  border-radius: 50px;
  background-image: ${({ orange }: { orange?: boolean }) =>
    orange
      ? 'linear-gradient(180deg, #FFB74D, #F57C00)'
      : 'linear-gradient(180deg, #a8cc1f, #5b9627)'};
  text-transform: unset;
  font-weight: bold;
  color: white;
  font-size: 18px;
  box-shadow: ${({ orange }: { orange?: boolean }) =>
    orange
      ? '0 3px 7px 3px rgba(255, 183, 77, 0.48)'
      : '0 3px 7px 3px rgba(95, 157, 41, 0.48)'};

  &:hover {
    background-image: ${({ orange }: { orange?: boolean }) =>
      orange
        ? 'linear-gradient(180deg, #F57C00, #F57C00)'
        : `linear-gradient(
      180deg,
      ${primary.light},
      ${primary.main}
    )`};
    color: ${({ orange }: { orange?: boolean }) =>
      orange ? 'white' : `${primary.dark}`};
  }
`;

export const ErrorGradientButton = styled(GradientButton)`
  margin-top: 0;
  margin-bottom: 0;
  background-image: linear-gradient(#fe453f, #a30702);
  border: none;
  box-shadow: 0 3px 7px 3px rgba(163, 7, 2, 0.48);

  &:hover {
    color: white;
    border: none;
    background-image: linear-gradient(#aa3f3f, #aa3f3f);
    box-shadow: 0 3px 7px 3px rgba(163, 7, 2, 0.48);
  }
`;

export const BorderButton = styled(LoadingButton)`
  padding: 10px 30px 10px 30px;
  border-radius: 50px;
  border: 3px solid ${primary.dark};
  text-transform: unset;
  font-weight: bold;
  color: black;
  transition: none;
  -webkit-transition: none;
  font-size: 18px;

  box-shadow: 0 3px 7px 3px rgba(173, 193, 61, 0.4);

  &:hover {
    border: 3px solid ${primary.main};
    box-shadow: 0 3px 7px 3px rgba(168, 195, 0, 0.4);
  }
`;

export const ErrorBorderButton = styled(BorderButton)`
  border: 3px solid #a30702;
  box-shadow: 0 3px 7px 3px rgba(163, 7, 2, 0.48);
  &:hover {
    border: 3px solid #fe453f;
    box-shadow: 0 3px 7px 3px rgba(254, 69, 63, 0.48);
  }
`;

type Props = BoxProps & {
  propsWidth?: string;
  data?: boolean;
};

export const SmallerBorderButton = styled(BorderButton)`
  padding: 5px 15px;
  height: 40px;
  font-size: 14px;
  margin: 0;
  font-weight: 600;

  @media (max-width: 660px) {
    font-size: 18px;
    padding: 10px 25px;
    height: auto;
    margin-top: 15px;
  }
`;

export const SmallerGradientButton = styled(GradientButton)`
  padding: 5px 15px;
  height: 40px;
  font-size: 14px;
  margin: 0;

  @media (max-width: 660px) {
    font-size: 18px;
    padding: 10px 25px;
    height: auto;
    margin: 15px auto;
  }
`;

export const RowWrap = styled(Box)`
  display: flex;
  justify-content: ${(p: Props) => (p.data ? 'space-evenly' : 'flex-start')};
  align-content: center;
  flex-direction: row;

  @media (max-width: ${(p: Props) => (p.propsWidth ? p.propsWidth : '400px')}) {
    justify-content: center;
    flex-direction: column;
  }
`;

export const PaymentTitle = styled(Typography)`
  background-color: #e2eaef;
  padding: 20px;
  border-radius: 25px;
  font-weight: 550;
  width: 100%;
  margin-right: 20px;

  @media (max-width: 905px) {
    border-radius: 25px 0 0 25px;
    width: 70%;
    text-align: left;
    margin: 0;
  }
`;

export const OrderRow = styled(Box)`
  display: flex;
  justify-content: space-around;
  padding-bottom: 20px;
  align-content: center;
  flex-direction: row;

  @media (max-width: 660px) {
    justify-content: space-around;
    padding: 20px 10px 10px 10px;
    flex-direction: column;
    gap: 10px;
  }
`;

export const JustRow = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-direction: row;

  @media (max-width: 660px) {
    justify-content: center;
    flex-direction: column;
  }
`;

export const LayoutWrapper = styled(Box)`
  display: flex;
  flex-flow: row wrap;
  padding-right: 20px;
  margin-top: 30px;

  @media (max-width: 905px) {
    margin-top: 0;
    flex-direction: column;
  }

  @media (max-width: 660px) {
    width: 100%;
    border-radius: 0;
    text-align: center;
    padding-left: 20px;
  }
`;

export const CardTitle = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 8px;
`;

export const CardDescription = styled(CardTitle)`
  font-size: 14px;

  @media (max-width: 660px) {
    margin-bottom: 20px;
  }
`;

export const CustomPaper = styled(Paper)`
  border-radius: 20px;
  margin-top: 24px;
  margin-right: 16px;
  box-shadow: 0px 16px 24px rgba(10, 60, 70, 0.16);

  @media (max-width: 660px) {
    margin-left: 16px;
  }
`;

export const Bold = styled('span')`
  font-weight: bold;
`;
