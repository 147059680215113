'use client';

const downloadPdf = (file_name: string, blob: Blob) => {
  const name = file_name.includes('filename=')
    ? file_name.substring(
        file_name.indexOf('"') + 1,
        file_name.lastIndexOf('"')
      )
    : file_name;

  const a = document.createElement('a');
  document.body.appendChild(a);

  a.href = URL.createObjectURL(blob);
  a.setAttribute('target', '_blank');
  a.download = name;
  a.click();
  URL.revokeObjectURL(a.href);
  a.remove();
};

export default downloadPdf;
