'use client';

export const types = {
  ADD_CREDIT_CARD_REQUEST: 'ADD_CREDIT_CARD_REQUEST',
  ADD_CREDIT_CARD_SUCCESS: 'ADD_CREDIT_CARD_SUCCESS',
  ADD_CREDIT_CARD_FAILURE: 'ADD_CREDIT_CARD_FAILURE',

  CARD_ADDED_SUCCESSFULLY: 'CARD_ADDED_SUCCESSFULLY',
  ACTION_NEEDED_TO_ADD: 'ACTION_NEEDED_TO_ADD',
  ACTION_DONE: 'ACTION_DONE',

  GET_POS_ID_REQUEST: 'GET_POS_ID_REQUEST',
  GET_POS_ID_SUCCESS: 'GET_POS_ID_SUCCESS',
  GET_POS_ID_FAILURE: 'GET_POS_ID_FAILURE',

  GET_CREDIT_CARD_REQUEST: 'GET_CREDIT_CARD_REQUEST',
  GET_CREDIT_CARD_SUCCESS: 'GET_CREDIT_CARD_SUCCESS',
  GET_CREDIT_CARD_FAILURE: 'GET_CREDIT_CARD_FAILURE',

  DELETE_CREDIT_CARD_REQUEST: 'DELETE_CREDIT_CARD_REQUEST',
  DELETE_CREDIT_CARD_SUCCESS: 'DELETE_CREDIT_CARD_SUCCESS',
  DELETE_CREDIT_CARD_FAILURE: 'DELETE_CREDIT_CARD_FAILURE',
  DELETE_CARD_DONE: 'DELETE_CARD_DONE',

  ADD_CARD_REDIRECTED_ON: 'ADD_CARD_REDIRECTED_ON',
  ADD_CARD_REDIRECTED_OFF: 'ADD_CARD_REDIRECTED_OFF',

  PAY_FOR_INVOICE_REQUEST: 'PAY_FOR_INVOICE_REQUEST',
  PAY_FOR_INVOICE_SUCCESS: 'PAY_FOR_INVOICE_SUCCESS',
  PAY_FOR_INVOICE_FAILURE: 'PAY_FOR_INVOICE_FAILURE',
  INVOICE_PAID_SUCCESSFULLY: 'INVOICE_PAID_SUCCESSFULLY',
  INVOICE_PAID_RESET: 'INVOICE_PAID_RESET',
};
