'use client';

import { TerytInterface } from '@redux/interfaces';
import { types } from './types';
import { types as loginTypes } from '@redux/auth/types';

export const INIT_STATE = {
  terytLoading: false,
  error: false,
  provinces: [],
  localities: [],
  streets: [],
  geoposition: {},
};

export default function reducer(
  state: TerytInterface = INIT_STATE,
  action: any
): TerytInterface {
  switch (action.type) {
    case loginTypes.LOGIN_REQUEST:
      return {
        ...INIT_STATE,
      };
    case types.GET_PROVINCES_REQUEST:
      return {
        ...state,
        terytLoading: true,
        error: false,
        localities: [],
        streets: [],
      };
    case types.GET_PROVINCES_FAILURE:
      return {
        ...state,
        terytLoading: false,
        error: true,
        provinces: [],
      };
    case types.GET_PROVINCES_SUCCESS:
      return {
        ...state,
        terytLoading: false,
        error: false,
        provinces: action.payload,
      };
    case types.GET_CITY_REQUEST:
      return {
        ...state,
        terytLoading: true,
        error: false,
        localities: [],
      };
    case types.GET_CITY_FAILURE:
      return {
        ...state,
        terytLoading: false,
        error: false,
        localities: [],
      };

    case types.GET_CITY_SUCCESS:
      return {
        ...state,
        terytLoading: false,
        error: false,
        localities: action.payload,
      };
    case types.GET_STREET_REQUEST:
      return {
        ...state,
        terytLoading: true,
        error: false,
      };
    case types.GET_STREET_FAILURE:
      return {
        ...state,
        terytLoading: false,
        error: false,
        streets: [],
      };

    case types.GET_STREET_SUCCESS:
      return {
        ...state,
        terytLoading: false,
        error: false,
        streets: action.payload,
      };

    case types.LOCALIZE_MARKER_SUCCESS:
      return {
        ...state,
        terytLoading: false,
        geoposition: action.data,
      };

    case types.RESET_TERYT_AFTER_ADD:
      return {
        ...state,
        provinces: [],
        localities: [],
        streets: [],
        geoposition: {},
      };

    case types.RESET_TERYT_STREET:
      return {
        ...state,
        streets: [],
      };
    case types.RESET_TERYT_LOCALITY:
      return {
        ...state,
        localities: [],
      };
    default:
      return state;
  }
}
