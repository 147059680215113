'use client';

export const types = {
  GET_DASHBOARD_INFO_REQUEST: 'GET_DASHBOARD_INFO_REQUEST',
  GET_DASHBOARD_INFO_SUCCESS: 'GET_DASHBOARD_INFO_SUCCESS',

  DELETE_FIREBASE_TOKEN_REQUEST: 'DELETE_FIREBASE_TOKEN_REQUEST',
  DELETE_FIREBASE_TOKEN_SUCCESS: 'DELETE_FIREBASE_TOKEN_SUCCESS',

  SET_FIREBASE_TOKEN_REQUEST: 'SET_FIREBASE_TOKEN_REQUEST',
  SET_FIREBASE_TOKEN_SUCCESS: 'SET_FIREBASE_TOKEN_SUCCESS',

  GET_UNREAD_NOTIFICATIONS_REQUEST: 'GET_UNREAD_NOTIFICATIONS_REQUEST',
  GET_UNREAD_NOTIFICATIONS_SUCCESS: 'GET_UNREAD_NOTIFICATIONS_SUCCESS',

  SET_READ_NOTIFICATIONS_REQUEST: 'SET_READ_NOTIFICATIONS_REQUEST',
  SET_READ_NOTIFICATIONS_SUCCESS: 'SET_READ_NOTIFICATIONS_SUCCESS',
  CLEAR_READ_NOTIFICATIONS: 'CLEAR_READ_NOTIFICATIONS',

  SET_CURRENT_TANK: 'SET_CURRENT_TANK',
};
