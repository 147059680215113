import type { AppContext, AppProps } from 'next/app';
import App from 'next/app';
import Head from 'next/head';
import { ThemeProvider } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import theme from '@styles/theme';
import React, { useEffect, useState } from 'react';

import '@styles/base/globals.css';
import '@styles/cardFormStyles.css';
import 'react-toastify/dist/ReactToastify.css';
import CookieBanner from '@components/elements/CookieBanner';

import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/700.css';
import '@fontsource/open-sans/800.css';

import 'leaflet/dist/leaflet.css';

import { ReduxProvider } from '@redux/provider';

import Custom404 from './404';
export interface MyAppProps extends AppProps {
  pageProps: {
    domain?: string;
    subdomainName?: string;
    coatOfArms?: string;
    error?: number;
  };
}

function MyApp({ Component, pageProps }: MyAppProps) {
  const [loaded, setLoaded] = useState(false);
  const [savedProps, setSavedProps] = useState<MyAppProps['pageProps']>({});

  useEffect(() => {
    if (pageProps) {
      setSavedProps(pageProps);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setLoaded(true);
      const loader = document.getElementById('globalLoader');
      if (loader) loader.remove();
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('online', () => {
        toast.success('Udało się przywrócić połączenie z internetem 🤓');
      });
      window.addEventListener('offline', () => {
        toast.error(
          'Jesteś offline 😖. Sprawdź swoje połączenie z internetem.'
        );
      });

      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('./offline-pages-sw.js');
      }
    }
  }, []);

  if (pageProps?.error) {
    return <Custom404 domain={pageProps.domain} />;
  }

  if (typeof window === 'undefined') return null;
  return (
    <ReduxProvider>
      <Head>
        <title>Szambo.Online</title>
      </Head>
      <ThemeProvider theme={theme}>
        {loaded && (
          <>
            <ToastContainer position="bottom-right" pauseOnHover />
            <CookieBanner />
            <Component {...savedProps} />
          </>
        )}
      </ThemeProvider>
    </ReduxProvider>
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const { ctx } = appContext;
  const props = await App.getInitialProps(appContext);

  const subdomain = ctx.req?.headers?.host
    ? ctx.req.headers.host.split('.')
    : '';
  const excludeSubdomain = ['localhost:3000', 'app-dev', 'app-stg', 'app'];
  const subdomainName = excludeSubdomain.includes(subdomain[0])
    ? undefined
    : subdomain[0];

  if (subdomainName) {
    if (process.env.NODE_ENV !== 'production') {
      // @ts-ignore
      process.env.NODE_TLS_REJECT_UNAUTHORIZED = 0;
    }
    const response = await fetch(
      process.env.BACKEND_URL +
        '/auth/registration/?' +
        new URLSearchParams({ subdomain_name: subdomainName }),
      { cache: 'force-cache' }
    );

    let data;

    if (response.ok) {
      data = await response.json();
    }

    props.pageProps = {
      error: !response.ok,
      domain: subdomain[1],
      subdomainName,
      coatOfArms: data?.coat_of_arms,
    };
  }

  return props;
};

export default MyApp;
