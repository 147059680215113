'use client';

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#A8C300',
      light: '#EAF5AD',
      dark: '#578C22',
      contrastText: '#506266',
    },
    secondary: {
      main: '#506266',
      light: '#B3C6C0',
      dark: '#061B1F',
    },
    info: {
      main: '#2196F3',
      light: '#64B5F6',
      dark: '#1976D2',
    },
    error: {
      main: '#F44336',
      light: '#E57373',
      dark: '#D32F2F',
    },
    warning: {
      main: '#FF9800',
      light: '#FFB74D',
      dark: '#F57C00',
    },
    text: {
      primary: '#1D2324',
      secondary: '#426E0F',
      disabled: '#9FA09F',
    },
    action: {
      disabled: 'rgba(0,0,0,0.26)',
      hover: 'rgba(0,0,0,0.04)',
      selected: 'rgba(0,0,0,0.08)',
    },
    background: {
      default: '#F6F9FB',
    },
    divider: 'rgba(0,0,0,0.12)',
  },
  typography: {
    fontFamily: 'Open Sans',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    h1: {
      fontSize: '3rem',
      letterSpacing: '-1.5px',
    },
    h2: {
      fontSize: '2.5rem',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '3rem',
    },
    h3: {
      fontSize: '2.25rem',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '2.75rem',
    },
    h4: {
      fontSize: '2rem',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '2.5rem',
    },
    h5: {
      fontSize: '1.5rem',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '1.75rem',
    },
    h6: {
      fontSize: '1.25rem',
      fontWeight: 500,
      letterSpacing: '0.15px',
      lineHeight: '2rem',
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 400,
      letterSpacing: '0.15px',
      lineHeight: '1.75rem',
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: 500,
      letterSpacing: '0.15px',
      lineHeight: '1.75rem',
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '1.375rem',
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: '1.25rem',
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '@media (max-width:1440px)': {
            '&.MuiInputLabel-shrink': {
              fontSize: '1rem',
            },
            '&:not(.MuiInputLabel-shrink)': {
              fontSize: '12px',
            },
          },
        },
      },
    },
  },
});

export default theme;
