'use client';

export const types = {
  UPDATE_CONTACT_DATA_REQUEST: 'UPDATE_CONTACT_DATA_REQUEST',
  UPDATE_CONTACT_DATA_SUCCESS: 'UPDATE_CONTACT_DATA_SUCCESS',
  UPDATE_CONTACT_DATA_FAILURE: 'UPDATE_CONTACT_DATA_FAILURE',

  GET_CONTACT_DATA_REQUEST: 'GET_CONTACT_DATA_REQUEST',
  GET_CONTACT_DATA_SUCCESS: 'GET_CONTACT_DATA_SUCCESS',
  GET_CONTACT_DATA_FAILURE: 'GET_CONTACT_DATA_FAILURE',

  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',

  GET_BILLING_DATA_REQUEST: 'GET_BILLING_DATA_REQUEST',
  GET_BILLING_DATA_SUCCESS: 'GET_BILLING_DATA_SUCCESS',
  GET_BILLING_DATA_FAILURE: 'GET_BILLING_DATA_FAILURE',

  UPDATE_BILLING_DATA_REQUEST: 'UPDATE_BILLING_DATA_REQUEST',
  UPDATE_BILLING_DATA_SUCCESS: 'UPDATE_BILLING_DATA_SUCCESS',
  UPDATE_BILLING_DATA_FAILURE: 'UPDATE_BILLING_DATA_FAILURE',
};
