import { put, select, takeLatest } from 'redux-saga/effects';
import { types } from './types';
import { types as cesspoolTypes } from '../userCesspools/types';
import { createSaga } from '@redux/sagas';
import {
  DashboardInterface,
  DashboardPropertiesInterface,
  DefaultApiAction,
  RootState,
} from '@redux/interfaces';
import { INIT_STATE } from '@redux/dashboard/reducer';

export function* getDashboardInfoSaga() {
  yield takeLatest(
    types.GET_DASHBOARD_INFO_REQUEST,
    createSaga('GET_DASHBOARD_INFO', '/client/desktop/', 'GET')
  );
}

export function* getDashboardInfoSuccessSaga() {
  const getState = (state: RootState) => state.dashboard;

  yield takeLatest(
    types.GET_DASHBOARD_INFO_SUCCESS,
    function* (action: DefaultApiAction) {
      const items: DashboardInterface = yield select(getState);
      const { client_properties } = action.payload;
      const {
        currentTank: { cesspool_name, id },
      } = items;

      let index;

      if (cesspool_name.length === 0 && client_properties.length >= 1) {
        index = 0;
      } else {
        const i = client_properties.findIndex(
          (t: DashboardPropertiesInterface) => t.id === id
        );
        index = i !== -1 ? i : 0;
      }
      const property = client_properties[index]
        ? client_properties[index]
        : INIT_STATE.currentTank;
      yield put({
        type: types.SET_CURRENT_TANK,
        data: property,
      });
      yield put({
        type: cesspoolTypes.SET_SELECTED_TANK_ID,
        data: property?.id,
      });
    }
  );
}

export function* setFirebaseTokenSaga() {
  yield takeLatest(
    types.SET_FIREBASE_TOKEN_REQUEST,
    createSaga(
      'SET_FIREBASE_TOKEN',
      '/client/notifications/registration-token/',
      'POST'
    )
  );
}

export function* deleteFirebaseTokenSaga() {
  yield takeLatest(
    types.DELETE_FIREBASE_TOKEN_REQUEST,
    createSaga(
      'DELETE_FIREBASE_TOKEN',
      '/client/notifications/registration-token/',
      'DELETE'
    )
  );
}

export function* getUnreadNotificationsSaga() {
  yield takeLatest(
    types.GET_UNREAD_NOTIFICATIONS_REQUEST,
    createSaga('GET_UNREAD_NOTIFICATIONS', '/client/notifications/', 'GET')
  );
}

export function* setReadNotificationsSaga() {
  yield takeLatest(
    types.SET_READ_NOTIFICATIONS_REQUEST,
    createSaga('SET_READ_NOTIFICATIONS', '/client/notifications/', 'PATCH')
  );
}

export function* getUnreadNotificationsSuccessSaga() {
  yield takeLatest(types.SET_READ_NOTIFICATIONS_SUCCESS, function* () {
    yield put({ type: types.GET_DASHBOARD_INFO_REQUEST });
    yield put({ type: types.GET_UNREAD_NOTIFICATIONS_REQUEST });
  });
}
