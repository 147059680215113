import CookieConsent from 'react-cookie-consent';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { AuthApiResult, RootState } from '@redux/interfaces';

const CustomLink = styled('a')`
  font-weight: bold;
  color: #a8c300;
`;

const CookieBanner = () => {
  const { documents } = useSelector<RootState, AuthApiResult>(
    (state) => state.auth
  );

  const getDocumentUri = (type: string) => {
    const item = documents.find((item) => item.type_code === type);

    return item?.uri;
  };

  return (
    <CookieConsent
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      disableButtonStyles={true}
      buttonText="Akceptuje"
      buttonClasses="cookieButton"
      customButtonProps={{
        'data-cy': 'cookie-consent',
      }}
    >
      🍪🍪🍪🍪🍪 Ta strona używa plików cookies (tzw. ciasteczka). Używamy ich w
      celach statystycznych, wykorzystujemy przy niektórych ważnych
      funkcjonalnościach oraz potrzebujemy ich do wyświetlania
      spersonalizowanych reklam. Możesz zaakceptować pliki cookies albo wyłączyć
      je w przeglądarce, dzięki czemu nie będziemy zbierać żadnych informacji.{' '}
      <CustomLink href="https://support.google.com/accounts/answer/61416">
        Dowiedz się jak wyłączyć pliki cookies
      </CustomLink>
      . Korzystając z serwisu wyrażasz zgodę na postanowienia{' '}
      <CustomLink
        href={getDocumentUri('DOC_PROCESSING_PERSONAL_DATA')}
        target="_blank"
        rel="noreferrer"
      >
        Polityki prywatności
      </CustomLink>{' '}
      i{' '}
      <CustomLink
        href={getDocumentUri('DOC_APP_TERMS_AND_CONDITIONS')}
        target="_blank"
        rel="noreferrer"
      >
        Regulaminu Szambo.Online
      </CustomLink>{' '}
      🍪🍪🍪🍪🍪
    </CookieConsent>
  );
};

export default CookieBanner;
