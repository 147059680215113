'use client';

import { MD5 as md5 } from 'crypto-js';

import { put, takeLatest } from 'redux-saga/effects';
import { types } from './types';
import { createSaga } from '@redux/sagas';
import { DefaultApiAction } from '@redux/interfaces';
import { toast } from 'react-toastify';
// @ts-ignore
import { isSafari } from '@firebase/util';
export function* addCardSaga() {
  yield takeLatest(
    types.ADD_CREDIT_CARD_REQUEST,
    createSaga('ADD_CREDIT_CARD', '/client/credit-card/', 'POST')
  );
}

export function* addCardSuccessSaga() {
  yield takeLatest(
    types.ADD_CREDIT_CARD_SUCCESS,
    function* (action: DefaultApiAction) {
      const { payload } = action;

      if (payload.status === 'WARNING' && payload.redirect_uri.length > 1) {
        if (!isSafari()) window.open(payload.redirect_uri);
        toast.success('Kontynuuj autoryzowanie karty płatniczej');
        yield put({ type: types.ACTION_NEEDED_TO_ADD });
      } else {
        toast.success('Karta została zapisana');
        yield put({ type: types.CARD_ADDED_SUCCESSFULLY });
      }
    }
  );
}

export function* addCardFailureSaga() {
  yield takeLatest(
    types.ADD_CREDIT_CARD_FAILURE,
    function* (action: DefaultApiAction) {
      if (action.payload.code === 302) {
        toast.info(action.payload.body.message);
      } else {
        toast.error(action.payload.body.message);
      }
    }
  );
}

// Pobieramy posId potrzebny do wygenerowania secureFormaPayu
export function* getPosIdSaga() {
  yield takeLatest(
    types.GET_POS_ID_REQUEST,
    createSaga('GET_POS_ID', '/client/credit-card/pos-id/', 'GET')
  );
}

export function* getPosIdFail() {
  yield takeLatest(
    types.GET_POS_ID_FAILURE,
    function* (action: DefaultApiAction) {
      toast.error('Błąd autoryzacji payu');
    }
  );
}

// pobieramy zanonimizowane dane karty klienta
export function* getCreditCardSaga() {
  yield takeLatest(
    types.GET_CREDIT_CARD_REQUEST,
    createSaga('GET_CREDIT_CARD', '/client/credit-card/', 'GET')
  );
}

// usuwamy kartę klienta
export function* deleteCreditCardSaga() {
  yield takeLatest(
    types.DELETE_CREDIT_CARD_REQUEST,
    createSaga('DELETE_CREDIT_CARD', '/client/credit-card', 'DELETE')
  );
}

export function* deleteCreditCardSuccessSaga() {
  yield takeLatest(
    types.DELETE_CREDIT_CARD_SUCCESS,
    function* (action: DefaultApiAction) {
      toast.success('Pomyślnie usunięto karte płatniczą');
      yield put({ type: types.GET_CREDIT_CARD_REQUEST });
    }
  );
}

export function* deleteCreditCardFailSaga() {
  yield takeLatest(
    types.DELETE_CREDIT_CARD_FAILURE,
    function* (action: DefaultApiAction) {
      toast.error('Nie udało się usunąć karty płatniczej');
    }
  );
}

export function* payForInvoiceSaga() {
  yield takeLatest(
    types.PAY_FOR_INVOICE_REQUEST,
    createSaga('PAY_FOR_INVOICE', '/client/invoice', 'POST')
  );
}

export function* payForInvoiceSuccessSaga() {
  yield takeLatest(
    types.PAY_FOR_INVOICE_SUCCESS,
    function* (action: DefaultApiAction) {
      const { payload } = action;

      if (payload?.status === 'WARNING' && payload?.redirect_uri.length) {
        if (payload?.payu_status === 'WARNING_CONTINUE_CVV') {
          toast.info('Wymagana dodatkowa autoryzacja CVV');
        } else {
          localStorage.setItem(
            'paymentInProgress',
            md5(String(action.id).replace('/', '')).toString()
          );
          window.location.href = payload.redirect_uri;
        }
      } else {
        toast.success('Udało się opłacić wywóz 🥳');
        yield put({ type: types.INVOICE_PAID_SUCCESSFULLY });
      }
    }
  );
}

export function* payForInvoiceFailureSaga() {
  yield takeLatest(
    types.PAY_FOR_INVOICE_FAILURE,
    function* (action: DefaultApiAction) {
      const { body } = action.payload;

      if (body?.message) {
        toast.error(body.message);
        yield put({ type: types.GET_CREDIT_CARD_REQUEST });
      } else {
        toast.error('Nie udało się zapłacić za wywóz!');
      }
    }
  );
}
