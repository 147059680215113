import { Store } from 'redux';
import { store } from '@redux/store';
import authService from '@services/authService';
import { toast } from 'react-toastify';
import { setBackOffline, setBackOnline } from '@redux/auth/actions';

const queryString = require('query-string');

interface RESTInterface {
  url: string;
  store?: Store;
  headers: Headers;

  call(
    endpoint: string,
    method: string,
    data?: object,
    formData?: FormData
  ): Promise<void | Response>;
}

export let abortController = new AbortController();

const RESTService: RESTInterface = {
  url: process.env.BACKEND_URL as string,

  get headers() {
    let TOKEN;
    if (window && window.sessionStorage) {
      TOKEN = sessionStorage.getItem('TOKEN');
    }

    const newHeaders = new Headers({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });

    if (TOKEN) {
      newHeaders.append('Authorization', `Token ${TOKEN}`);
    }

    return newHeaders;
  },

  call: async function (
    /** call and API and returns a Promise */
    endpoint: string,
    method: string = 'GET',
    data?: object,
    formData?: FormData
  ) {
    abortController = new AbortController();

    const fetchOpt: any = {
      method: method,
      headers: RESTService.headers,
      mode: 'cors',
      signal: abortController.signal,
    };

    if (method !== 'GET' && data) {
      fetchOpt.body = JSON.stringify(data);
    }

    if (method === 'GET' && data) {
      endpoint += '?' + queryString.stringify(data, { arrayFormat: 'bracket' });
    }

    if (formData) {
      fetchOpt.body = formData;
      fetchOpt.headers.delete('Content-Type');
    }

    return await fetch(RESTService.url + endpoint, fetchOpt)
      .then((res) => {
        const { isOffline } = store.getState().auth;

        if (res.status === 401) {
          console.log('[RESTService] User unauthorized');
          authService.logoutUser();
        } else if (Number(res.status) >= 500) {
          toast.error('Wystąpił węwnętrzny problem. Spróbuj ponownie później.');
        }

        if (isOffline) {
          store.dispatch(setBackOnline());
        }

        return res;
      })
      .catch((e) => {
        if (typeof e === 'string' && !e.includes('AbortError')) {
          store.dispatch(setBackOffline());
        }
      });
  },
};

export default RESTService;
