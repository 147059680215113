import { TankDataInterface } from '@forms/TankDataForm/interface';

const getDate = (date: string | null) => {
  if (date) {
    const currentDate = new Date(date);
    return (
      currentDate.getFullYear() +
      '-' +
      (currentDate.getMonth() + 1) +
      '-' +
      currentDate.getDate()
    );
  }
  return null;
};

export const prepareTankDataObject = (values: TankDataInterface) => {
  const {
    tankType,
    tankModel,
    sewageType,
    capacity,
    emptyingFrequency,
    dailyCapacity,
    launchDate,
    residentsAmount,
    waterSource,
    registeredPersons,
    cesspoolTankInstallationDate,
    sewageSystem,
    sewageSystemConnectionDate,
  } = values;

  return {
    sewage_group: sewageType,
    sewage_type: tankType,
    resident_count: residentsAmount,
    tank_type: tankModel,
    cesspool_size: capacity,
    ...(tankType === 'OSSF' && {
      launch_date: getDate(launchDate),
      flow_capacity: dailyCapacity.replaceAll(',', '.'),
      frequency: emptyingFrequency,
    }),
    water_source: waterSource,
    sewage_system: sewageSystem,
    cesspool_tank_installation_date: getDate(cesspoolTankInstallationDate),
    registered_people_count: registeredPersons,
    sewage_system_connection_date: getDate(sewageSystemConnectionDate),
  };
};
