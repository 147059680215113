'use client';

export const types = {
  SET_SELECTED_TANK_ID: 'SET_SELECTED_TANK_ID',
  ADD_NEW_TANK: 'ADD_NEW_TANK',
  SET_NEW_TANK_NAME: 'SET_NEW_TANK_NAME',
  REMOVE_NEW_TANK: 'REMOVE_NEW_TANK',
  SET_TANK_DATA_VALUES: 'SET_TANK_DATA_VALUES',

  // GET /client/property/
  GET_CLIENT_PROPERTY_REQUEST: 'GET_CLIENT_PROPERTY_REQUEST',
  GET_CLIENT_PROPERTY_SUCCESS: 'GET_CLIENT_PROPERTY_SUCCESS',
  GET_CLIENT_PROPERTY_FAILURE: 'GET_CLIENT_PROPERTY_FAILURE',

  // DELETE /client/property/{id}/
  DELETE_TANK_REQUEST: 'DELETE_TANK_REQUEST',
  DELETE_TANK_SUCCESS: 'DELETE_TANK_SUCCESS',
  DELETE_TANK_FAILURE: 'DELETE_TANK_FAILURE',
  DELETE_TANK_RESET: 'DELETE_TANK_RESET',

  // GET /client/property/{id}/
  GET_TANK_DETAILS_REQUEST: 'GET_TANK_DETAILS_REQUEST',
  GET_TANK_DETAILS_SUCCESS: 'GET_TANK_DETAILS_SUCCESS',
  NEW_ACCOUNT_LOADING_FALSE: 'NEW_ACCOUNT_LOADING_FALSE',

  // POST /client/property/
  SET_NEW_TANK_LOCALIZATION_REQUEST: 'SET_NEW_TANK_LOCALIZATION_REQUEST',
  SET_NEW_TANK_LOCALIZATION_SUCCESS: 'SET_NEW_TANK_LOCALIZATION_SUCCESS',

  // PUT /client/property/{id}/
  UPDATE_TANK_LOCALIZATION_REQUEST: 'UPDATE_TANK_LOCALIZATION_REQUEST',
  UPDATE_TANK_LOCALIZATION_SUCCESS: 'UPDATE_TANK_LOCALIZATION_SUCCESS',

  // PUT /client/cesspool-data/{id}/
  UPDATE_TANK_DATA_REQUEST: 'UPDATE_TANK_DATA_REQUEST',
  UPDATE_TANK_DATA_SUCCESS: 'UPDATE_TANK_DATA_SUCCESS',
  UPDATE_TANK_DATA_FAILURE: 'UPDATE_TANK_DATA_FAILURE',

  // PUT /client/virtual-control/authcode/
  ASSIGN_VIRTUAL_CONTROL_CODE: 'ASSIGN_VIRTUAL_CONTROL_CODE',
  ASSIGN_VIRTUAL_CONTROL_CODE_FAILURE: 'ASSIGN_VIRTUAL_CONTROL_CODE_FAILURE',
  ASSIGN_VIRTUAL_CONTROL_CODE_SUCCESS: 'ASSIGN_VIRTUAL_CONTROL_CODE_SUCCESS',

  // `PUT` /client/protocol/{cesspool_id}/
  UPLOAD_SIGNED_PROTOCOL_REQUEST: 'UPLOAD_SIGNED_PROTOCOL_REQUEST',
  UPLOAD_SIGNED_PROTOCOL_SUCCESS: 'UPLOAD_SIGNED_PROTOCOL_SUCCESS',
  UPLOAD_SIGNED_PROTOCOL_FAILURE: 'UPLOAD_SIGNED_PROTOCOL_FAILURE',

  // `POST` /client/protocol/{cesspool_id}/
  SEND_COMMENT_PROTOCOL_REQUEST: 'SEND_COMMENT_PROTOCOL_REQUEST',
  SEND_COMMENT_PROTOCOL_SUCCESS: 'SEND_COMMENT_PROTOCOL_SUCCESS',
  SEND_COMMENT_PROTOCOL_FAILURE: 'SEND_COMMENT_PROTOCOL_FAILURE',

  // GET /client/protocol/{cesspool_id}/
  GET_PROTOCOL_ID_REQUEST: 'GET_PROTOCOL_ID_REQUEST',
  GET_PROTOCOL_ID_SUCCESS: 'GET_PROTOCOL_ID_SUCCESS',
  GET_PROTOCOL_ID_FAILURE: 'GET_PROTOCOL_ID_FAILURE',
  RESET_PROTOCOL_ID: 'RESET_PROTOCOL_ID',

  // GET /client/protocol/{cesspool_id}/{task_id}
  GET_PROTOCOL_FILE_REQUEST: 'GET_PROTOCOL_FILE_REQUEST',
  GET_PROTOCOL_FILE_SUCCESS: 'GET_PROTOCOL_FILE_SUCCESS',
  GET_PROTOCOL_FILE_FAILURE: 'GET_PROTOCOL_FILE_FAILURE',

  // GET /client/protocol/{cesspool_id}/
  GET_PROTOCOL_DETAILS_REQUEST: 'GET_PROTOCOL_DETAILS_REQUEST',
  GET_PROTOCOL_DETAILS_SUCCESS: 'GET_PROTOCOL_DETAILS_SUCCESS',
  GET_PROTOCOL_DETAILS_FAILURE: 'GET_PROTOCOL_DETAILS_FAILURE',
};
