'use client';

import { MyExportsInterface } from '@redux/interfaces';
import { types } from './types';
import { types as loginTypes } from '@redux/auth/types';

export const INIT_STATE = {
  loading: false,
  error: false,
  isAdded: false,
  companies: [],
  historyDrop: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  needRefresh: false,
  complaintDetail: {
    created_at: null,
    order: null,
    complaint: '',
    order_date: '',
    client_property_name: '',
    sanitation_company_name: '',
    amount: null,
    price: null,
  },
  complaintLoading: false,
  showNoCompanyInfo: false,
};

export default function reducer(
  state: MyExportsInterface = INIT_STATE,
  action: any
): MyExportsInterface {
  switch (action.type) {
    case loginTypes.LOGIN_REQUEST:
      return {
        ...INIT_STATE,
      };

    case types.ADD_NEW_EXPORT_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        isAdded: false,
      };
    case types.ADD_NEW_EXPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        isAdded: true,
        needRefresh: true,
      };
    case types.ADD_NEW_EXPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case types.CONFIRM_IS_ADDED:
      return {
        ...state,
        isAdded: false,
      };
    case types.FIND_COMPANY_REQUEST:
      return {
        ...state,
        error: false,
        showNoCompanyInfo: false,
      };
    case types.FIND_COMPANY_SUCCESS:
      return {
        ...state,
        error: false,
        companies: action.payload,
      };
    case types.FIND_COMPANY_RESET:
      return {
        ...state,
        companies: [],
        showNoCompanyInfo: false,
      };
    case types.SHOW_NO_COMPANY_INFO:
      return {
        ...state,
        showNoCompanyInfo: true,
      };
    case types.GET_HISTORY_DROP_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
        needRefresh: false,
      };
    case types.GET_HISTORY_DROP_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        historyDrop: action.payload,
      };
    case types.DELETE_EXPORT_SUCCESS:
      return {
        ...state,
        needRefresh: true,
      };
    case types.UPDATE_EXPORT_SUCCESS:
      return {
        ...state,
        needRefresh: true,
        isAdded: true,
      };
    case types.GET_COMPLAINT_REQUEST:
      return {
        ...state,
        complaintLoading: true,
      };
    case types.GET_COMPLAINT_FAILURE:
    case types.RESET_COMPLAINT:
      return {
        ...state,
        complaintDetail: INIT_STATE.complaintDetail,
        complaintLoading: false,
        needRefresh: false,
      };
    case types.GET_COMPLAINT_SUCCESS:
      return {
        ...state,
        complaintDetail: action.payload,
        complaintLoading: false,
      };
    case types.ADD_COMPLAINT_REQUEST:
      return {
        ...state,
        complaintLoading: true,
      };
    case types.ADD_COMPLAINT_SUCCESS:
      return {
        ...state,
        complaintLoading: false,
        needRefresh: true,
      };
    case types.ADD_COMPLAINT_FAILURE:
      return {
        ...state,
        complaintLoading: false,
        needRefresh: false,
      };
    default:
      return state;
  }
}
