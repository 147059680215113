'use client';

export const types = {
  GET_PROVINCES_REQUEST: 'GET_PROVINCES_REQUEST',
  GET_PROVINCES_SUCCESS: 'GET_PROVINCES_SUCCESS',
  GET_PROVINCES_FAILURE: 'GET_PROVINCES_FAILURE',

  GET_CITY_REQUEST: 'GET_CITY_REQUEST',
  GET_CITY_SUCCESS: 'GET_CITY_SUCCESS',
  GET_CITY_FAILURE: 'GET_CITY_FAILURE',

  GET_STREET_REQUEST: 'GET_STREET_REQUEST',
  GET_STREET_SUCCESS: 'GET_STREET_SUCCESS',
  GET_STREET_FAILURE: 'GET_STREET_FAILURE',

  LOCALIZE_MARKER_FAIL: 'LOCALIZE_MARKER_FAIL',
  LOCALIZE_MARKER_SUCCESS: 'LOCALIZE_MARKER_SUCCESS',

  RESET_TERYT_AFTER_ADD: 'RESET_TERYT_AFTER_ADD',
  RESET_TERYT_STREET: 'RESET_TERYT_STREET',
  RESET_TERYT_LOCALITY: 'RESET_TERYT_LOCALITY',
};
