'use client';

import { UserTanksInterface } from '@redux/interfaces';
import { types } from './types';
import { types as loginTypes } from '@redux/auth/types';

export const INIT_STATE = {
  loading: false,
  loadingGetProtocol: false,
  protocolTaskId: null,
  tankDetailsLoading: true,
  error: false,
  selectedTankId: 0,
  clientProperty: [
    {
      id: 0,
      get_address: '',
      cesspool_name: '',
      has_order: false,
      has_debt: false,
      has_order_to_accept: false,
    },
  ],
  currentTank: {
    cesspool_name: '',
    postal_code: '',
    city: '',
    locality_sym: '',
    street: '',
    street_sym: 0,
    house_nb: '',
    flat_nb: '',
    province: { name: '', code: '0' },
    geoposition: { lat: 0, lng: 0 },
    cesspoolfeatures: {
      sewage_group: '',
      sewage_type: '',
      cesspool_size: '',
      tank_type: '',
      resident_count: '',
      water_source: false,
      sewage_system_connection_date: '',
      sewage_system: false,
      cesspool_tank_installation_date: '',
      registered_people_count: '',
    },
    ossffeatures: null,
    agreement_set: [],
    can_delete: true,
    has_discount: false,
    virtual_control_auth_code: '',
    virtual_control_commune_name: '',
    virtual_control_permission: false,
    virtual_control_protocol: null,
  },
  newName: 'Mój zbiornik',
  addingNewTank: false,
  tankDeleted: 'TO_DELETE',
  tankDataValues: null,
  protocolDetails: null,
};

export default function reducer(
  state: UserTanksInterface = INIT_STATE,
  action: any
): UserTanksInterface {
  switch (action.type) {
    case loginTypes.LOGIN_REQUEST:
      return {
        ...INIT_STATE,
      };
    case types.SET_SELECTED_TANK_ID:
      return {
        ...state,
        selectedTankId: action.data,
        protocolDetails: null,
      };
    case types.ADD_NEW_TANK:
      return {
        ...state,
        currentTank: action.newTankData.tank,
        clientProperty: [
          ...state.clientProperty,
          action.newTankData.newProperty,
        ],
        newName: 'Mój zbiornik',
        addingNewTank: true,
      };
    case types.SET_NEW_TANK_NAME:
      return {
        ...state,
        newName: action.data,
      };
    case types.GET_CLIENT_PROPERTY_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        newName: 'Mój zbiornik',
        addingNewTank: false,
      };
    case types.GET_CLIENT_PROPERTY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        clientProperty: action.payload.reverse(),
      };
    case types.GET_CLIENT_PROPERTY_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case types.GET_TANK_DETAILS_REQUEST:
      return {
        ...state,
        tankDetailsLoading: true,
        error: false,
        currentTank: INIT_STATE.currentTank,
      };
    case types.GET_TANK_DETAILS_SUCCESS:
      return {
        ...state,
        tankDetailsLoading: false,
        error: false,
        currentTank: {
          ...action.payload,
        },
        addingNewTank: false,
      };
    case types.NEW_ACCOUNT_LOADING_FALSE:
      return {
        ...state,
        tankDetailsLoading: false,
      };
    case types.UPDATE_TANK_LOCALIZATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        currentTank: { ...state.currentTank, ...action.data },
      };
    case types.UPDATE_TANK_LOCALIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        currentTank: { ...state.currentTank, ...action.data },
      };
    case types.UPDATE_TANK_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case types.UPDATE_TANK_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case types.UPDATE_TANK_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        currentTank: {
          ...state.currentTank,
          ...(action.payload.sewage_type === 'OSSF'
            ? { ossffeatures: action.payload, cesspoolfeatures: null }
            : { cesspoolfeatures: action.payload, ossffeatures: null }),
        },
      };
    case types.SET_NEW_TANK_LOCALIZATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case types.SET_NEW_TANK_LOCALIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        newName: 'Mój zbiornik',
      };
    case types.DELETE_TANK_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case types.DELETE_TANK_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        tankDeleted: 'DELETED',
      };
    case types.DELETE_TANK_FAILURE:
      return {
        ...state,
        loading: false,
        error: false,
        tankDeleted: 'NOT_DELETED',
      };
    case types.DELETE_TANK_RESET:
      return {
        ...state,
        tankDeleted: 'TO_DELETE',
      };
    case types.SET_TANK_DATA_VALUES:
      return {
        ...state,
        tankDataValues: action.data,
      };
    case types.REMOVE_NEW_TANK:
      const filteredProperties = state.clientProperty.filter(
        (item) => item.id !== 0
      );

      return {
        ...state,
        clientProperty: filteredProperties,
        selectedTankId:
          state.clientProperty.length > 0 ? state.clientProperty[0].id : 0,
      };

    case types.UPLOAD_SIGNED_PROTOCOL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.UPLOAD_SIGNED_PROTOCOL_SUCCESS:
      return {
        ...state,
        currentTank: {
          ...state.currentTank,
          virtual_control_protocol: action.payload,
        },
        loading: false,
      };
    case types.UPLOAD_SIGNED_PROTOCOL_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case types.GET_PROTOCOL_ID_REQUEST:
      return {
        ...state,
        loadingGetProtocol: true,
        protocolTaskId: null,
      };
    case types.GET_PROTOCOL_ID_SUCCESS:
      return {
        ...state,
        protocolTaskId: action.payload?.id ? action.payload.id : null,
        loadingGetProtocol: !!action.payload?.id,
      };
    case types.GET_PROTOCOL_ID_FAILURE:
      return {
        ...state,
        loadingGetProtocol: false,
      };
    case types.RESET_PROTOCOL_ID:
      return {
        ...state,
        protocolTaskId: null,
        loadingGetProtocol: false,
      };
    case types.GET_PROTOCOL_DETAILS_REQUEST:
      return {
        ...state,
        protocolDetails: null,
        loading: true,
      };
    case types.GET_PROTOCOL_DETAILS_FAILURE:
      return {
        ...state,
        protocolDetails: null,
        loading: false,
      };
    case types.GET_PROTOCOL_DETAILS_SUCCESS:
      return {
        ...state,
        protocolDetails: action.payload,
        loading: false,
      };
    case types.SEND_COMMENT_PROTOCOL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.SEND_COMMENT_PROTOCOL_SUCCESS:
      return {
        ...state,
        loading: false,
        currentTank: {
          ...state.currentTank,
          virtual_control_protocol: action.payload,
        },
      };
    case types.SEND_COMMENT_PROTOCOL_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
