import { NextPage } from 'next';
import Lottie from 'lottie-react';
import { BorderButton } from '@styles/styled';
import { Box, Container, Paper, Typography } from '@mui/material';
import BackgroundImageLayout from '@components/layouts/BackgroundImageLayout';
import { styled } from '@mui/material/styles';

import error from '../../../public/lottie/error.json';
import logo from '../../../public/images/logo.svg';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { MyAppProps } from '../../pages/_app';

const BackgroundImage = styled(BackgroundImageLayout)`
  align-items: center;

  @media (max-width: 660px) {
    align-items: center;
  }
`;

const NotFound: NextPage<MyAppProps['pageProps']> = ({ domain }) => {
  const router = useRouter();

  const handleClick = () => {
    if (domain) {
      window.location.href =
        process.env.ENV_TYPE === 'DEV'
          ? `http://${domain}/`
          : `http://${domain}.online/`;
    } else {
      router.push('/');
    }
  };

  return (
    <BackgroundImage
      additionalStyle={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Container maxWidth="md">
        <Paper
          variant="outlined"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            borderRadius: 10,
            padding: 5,
            minHeight: '50vh',
          }}
        >
          <Image src={logo} alt={'logo'} height={72} width={250} />
          <Typography align="center" mt={3} mb={3} fontSize={22}>
            Strona nie istnieje...
          </Typography>
          <Lottie animationData={error} loop={true} />
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <BorderButton onClick={handleClick}>Wróć na pulpit</BorderButton>
          </Box>
        </Paper>
      </Container>
    </BackgroundImage>
  );
};

export default NotFound;
