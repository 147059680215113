'use client';

import { put, takeLatest } from 'redux-saga/effects';
import { types } from './types';
import { types as terytTypes } from '../../teryt/types';

import { createSaga } from '@redux/sagas';
import { DefaultApiAction } from '@redux/interfaces';
import { toast } from 'react-toastify';

export function* updateClientSaga() {
  yield takeLatest(
    types.UPDATE_CONTACT_DATA_REQUEST,
    createSaga('UPDATE_CONTACT_DATA', '/client/contact-data/', 'PUT')
  );
}
export function* updateClientSuccessSaga() {
  yield takeLatest(
    types.UPDATE_CONTACT_DATA_SUCCESS,
    function* (action: DefaultApiAction) {
      toast.success('Pomyślnie zmieniono dane');
      yield put({ type: types.GET_CONTACT_DATA_REQUEST });
    }
  );
}
export function* updateClientFailureSaga() {
  yield takeLatest(
    types.UPDATE_CONTACT_DATA_FAILURE,
    function* (action: DefaultApiAction) {
      toast.error('Nie udało się zmienić danych');
    }
  );
}

export function* getClientContactDataSaga() {
  yield takeLatest(
    types.GET_CONTACT_DATA_REQUEST,
    createSaga('GET_CONTACT_DATA', '/client/contact-data/')
  );
}

export function* getClientContactDataSuccessSaga() {
  yield takeLatest(
    types.GET_CONTACT_DATA_SUCCESS,
    function* (action: DefaultApiAction) {}
  );
}

export function* getClientContactDataFailureSaga() {
  yield takeLatest(
    types.GET_CONTACT_DATA_FAILURE,
    function* (action: DefaultApiAction) {
      toast.error('Nie udało się pobrać danych klienta');
    }
  );
}

export function* changePasswordSaga() {
  yield takeLatest(
    types.CHANGE_PASSWORD_REQUEST,
    createSaga('CHANGE_PASSWORD', '/auth/password/change/', 'POST')
  );
}
export function* changePasswordSuccessSaga() {
  yield takeLatest(
    types.CHANGE_PASSWORD_SUCCESS,
    function* (action: DefaultApiAction) {
      toast.success('Hasło zostało pomyślnie zmienione');
    }
  );
}
export function* changePasswordFailureSaga() {
  yield takeLatest(
    types.CHANGE_PASSWORD_FAILURE,
    function* (action: DefaultApiAction) {
      toast.error('Nie udało się zmienić hasła');
    }
  );
}

export function* getClientBillingDataSaga() {
  yield takeLatest(
    types.GET_BILLING_DATA_REQUEST,
    createSaga('GET_BILLING_DATA', '/client/billing/')
  );
}

export function* getClientBillingDataSuccessSaga() {
  yield takeLatest(
    types.GET_BILLING_DATA_SUCCESS,
    function* (action: DefaultApiAction) {}
  );
}

export function* updateClientBillingSaga() {
  yield takeLatest(
    types.UPDATE_BILLING_DATA_REQUEST,
    createSaga('UPDATE_BILLING_DATA', '/client/billing/', 'PUT')
  );
}
export function* updateClientBillingSuccessSaga() {
  yield takeLatest(
    types.UPDATE_BILLING_DATA_SUCCESS,
    function* (action: DefaultApiAction) {
      toast.success('Pomyślnie zmieniono dane');
      yield put({ type: types.GET_BILLING_DATA_REQUEST });
      yield put({ type: terytTypes.RESET_TERYT_AFTER_ADD });
    }
  );
}
