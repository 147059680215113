'use client';

import auth from './auth/reducer';
import contact from './userSettings/myAccount/reducer';
import order from './order/reducers';
import teryt from './teryt/reducers';
import userCesspools from './userCesspools/reducers';
import payment from './payment/reducer';
import dashboard from './dashboard/reducer';
import myExports from './myExports/reducers';
import agreements from './agreements/reducers';

export default {
  auth,
  contact,
  teryt,
  userCesspools,
  payment,
  order,
  dashboard,
  myExports,
  agreements,
};
