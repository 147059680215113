'use client';

import { takeLatest } from 'redux-saga/effects';
import { createSaga } from '@redux/sagas';

import { toast } from 'react-toastify';

import { types } from './types';
import { DefaultApiAction } from '@redux/interfaces';

export function* getProvincesSaga() {
  yield takeLatest(
    types.GET_PROVINCES_REQUEST,
    createSaga('GET_PROVINCES', `/teryt/provinces/`, 'GET')
  );
}

export function* getProvincesSuccessSaga() {
  yield takeLatest(
    types.GET_PROVINCES_SUCCESS,
    function* (action: DefaultApiAction) {}
  );
}

export function* getProvincesFailureSaga() {
  yield takeLatest(
    types.GET_PROVINCES_FAILURE,
    function* (action: DefaultApiAction) {
      toast.error('Nie udało się pobrać województw');
    }
  );
}

export function* getCitiesSaga() {
  yield takeLatest(
    types.GET_CITY_REQUEST,
    createSaga('GET_CITY', `/teryt/localities/`, 'GET')
  );
}

export function* getCitiesSuccessSaga() {
  yield takeLatest(
    types.GET_CITY_SUCCESS,
    function* (action: DefaultApiAction) {}
  );
}

export function* getCitiesFailureSaga() {
  yield takeLatest(
    types.GET_CITY_FAILURE,
    function* (action: DefaultApiAction) {
      toast.error('Nie udało się pobrać miasta');
    }
  );
}

export function* getStreetsSaga() {
  yield takeLatest(
    types.GET_STREET_REQUEST,
    createSaga('GET_STREET', `/teryt/streets/`, 'GET')
  );
}

export function* getStreetsSuccessSaga() {
  yield takeLatest(
    types.GET_STREET_SUCCESS,
    function* (action: DefaultApiAction) {}
  );
}

export function* getStreetsFailureSaga() {
  yield takeLatest(
    types.GET_STREET_FAILURE,
    function* (action: DefaultApiAction) {
      toast.error('Nie udało się pobrać ulicy');
    }
  );
}

export function* getLocalizationSagaSuccess() {
  yield takeLatest(
    types.LOCALIZE_MARKER_SUCCESS,
    function* (action: DefaultApiAction) {
      toast.success('Pomyślnie zlokalizowano');
    }
  );
}
